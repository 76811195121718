export type FormErrors =
  | CouponsErrorType
  | EmptyStateErrorType
  | GenericErrorType
  | BookErrorType;

export enum CouponsErrorType {
  COUPON_HAS_EXPIRED = 'ERROR_COUPON_HAS_EXPIRED',
  COUPON_USAGE_EXCEEDED = 'ERROR_COUPON_USAGE_EXCEEDED',
  COUPON_SERVICE_UNAVAILABLE = 'ERROR_COUPON_SERVICE_UNAVAILABLE',
  COUPON_DOES_NOT_EXIST = 'ERROR_COUPON_DOES_NOT_EXIST',
  NOT_VALID_FOR_SELECTED_SERVICE = 'ERROR_INVALID_PRODUCTS',
  GENERAL_SERVER_ERROR = 'ERROR_COUPON_GENERAL',
  COUPON_LIMIT_PER_CUSTOMER_EXCEEDED = 'COUPON_LIMIT_PER_CUSTOMER_EXCEEDED',
}

export enum EmptyStateErrorType {
  INVALID_SLOT_AVAILABILITY = 'invalid_slot_availability',
  INVALID_SERVICE_ID = 'invalid_service_id',
  INVALID_CATALOG_DATA = 'invalid_catalog_data',
  COURSE_WITHOUT_SESSIONS = 'COURSE_WITHOUT_SESSIONS',
  NO_COURSE_AVAILABILITY = 'NO_COURSE_AVAILABILITY',
  NO_LIST_SLOTS = 'NO_LIST_SLOTS',
  NO_PRICING_PLAN_INSTALLED_BUT_SERVICE_OFFERED_ONLY_AS_PP = 'NO_PRICING_PLAN_INSTALLED_BUT_SERVICE_OFFERED_ONLY_AS_PP',
}

export enum GenericErrorType {
  EXCEEDED_ALLOWED_MAX_NUMBER_OF_PARTICIPANTS = 'EXCEEDED_ALLOWED_MAX_NUMBER_OF_PARTICIPANTS',
  SESSION_CAPACITY_EXCEEDED = 'SESSION_CAPACITY_EXCEEDED',
  GENERIC_PRICING_PLAN_ERROR = 'GENERIC_PRICING_PLAN_ERROR',
  GENERIC_MEMBER_DETAILS_ERROR = 'GENERIC_MEMBER_DETAILS_ERROR',
  GENERIC_BOOK_ERROR = 'GENERIC_BOOK_ERROR',
}

export enum BookErrorType {
  SLOT_NOT_AVAILABLE = 'SLOT_NOT_AVAILABLE',
  CANT_BOOK_CANCELED_SESSION = 'CANT_BOOK_CANCELED_SESSION',
  SESSION_TIME_MISMATCH = 'SESSION_TIME_MISMATCH',
  BOOKING_POLICY_VIOLATION = 'BOOKING_POLICY_VIOLATION',
}

export enum BookingsErrorCodes {
  BOOKING_POLICY_VIOLATION = '3',
  SLOT_NOT_AVAILABLE = '14',
  CANT_BOOK_CANCELED_SESSION = '11',
  SESSION_TIME_MISMATCH = '20',
  EXCEEDED_ALLOWED_MAX_NUMBER_OF_PARTICIPANTS = '4',
  SESSION_CAPACITY_EXCEEDED = '9',
  COUPON_LIMIT_PER_CUSTOMER_EXCEEDED = '29',
}
